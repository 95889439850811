// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes zoomIn {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(20);
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
`, "",{"version":3,"sources":["webpack://./src/modules/biometric-auth/css/task-icon.css"],"names":[],"mappings":"AAAA;CACC;EACC,mBAAmB;CACpB;CACA;EACC,oBAAoB;CACrB;AACD;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD","sourcesContent":["@keyframes zoomIn {\n\t0% {\n\t\ttransform: scale(1);\n\t}\n\t100% {\n\t\ttransform: scale(20);\n\t}\n}\n\n@keyframes fadeOut {\n\t0% {\n\t\topacity: 1;\n\t}\n\t100% {\n\t\topacity: 0;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
