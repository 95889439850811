export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const UPLOAD_AVATAR_REQUEST = "UPLOAD_AVATAR_REQUEST";
export const UPLOAD_AVATAR_SUCCESS = "UPLOAD_AVATAR_SUCCESS";
export const UPLOAD_AVATAR_FAILED = "UPLOAD_AVATAR_FAILED";
export const UPLOAD_AVATAR_RESET = "UPLOAD_AVATAR_RESET";

export const DELETE_AVATAR_REQUEST = "DELETE_AVATAR_REQUEST";
export const DELETE_AVATAR_SUCCESS = "DELETE_AVATAR_SUCCESS";
export const DELETE_AVATAR_FAILED = "DELETE_AVATAR_FAILED";

export const SEND_MAIL_REQUEST = "SEND_MAIL_REQUEST";
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS";
export const SEND_MAIL_ERROR = "SEND_MAIL_ERROR";
export const SEND_MAIL_RESET = "SEND_MAIL_RESET";
export const CHECK_TRANSFERABLE_WALLET_MODAL = "CHECK_TRANSFERABLE_WALLET_MODAL";
export const CHECK_TRANSFERABLE_REQUEST = "CHECK_TRANSFERABLE_REQUEST";
export const CHECK_TRANSFERABLE_SUCCESS = "CHECK_TRANSFERABLE_SUCCESS";
export const CHECK_TRANSFERABLE_ERROR = "CHECK_TRANSFERABLE_ERROR";

export const CHECK_ACCOUNT_SIGNATURE_REQUEST = "CHECK_ACCOUNT_SIGNATURE_REQUEST";
export const CHECK_ACCOUNT_SIGNATURE_SUCCESS = "CHECK_ACCOUNT_SIGNATURE_SUCCESS";
export const CHECK_ACCOUNT_SIGNATURE_ERROR = "CHECK_ACCOUNT_SIGNATURE_ERROR";
export const CHECK_ACCOUNT_SIGNATURE_RESET = "CHECK_ACCOUNT_SIGNATURE_RESET";
export const CHECK_TOKEN_REQUEST = "CHECK_TOKEN_REQUEST";

export const PASS_KEY_REQUEST = "PASS_KEY_REQUEST";
export const PASS_KEY_SUCCESS = "PASS_KEY_SUCCESS";
export const PASS_KEY_ERROR = "PASS_KEY_ERROR";
export const PASS_KEY_RESET = "PASS_KEY_RESET";

export const CUSTOMIZED_COLUMNS_OPEN_ORDER = "CUSTOMIZED_COLUMNS_OPEN_ORDER";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";