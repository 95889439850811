export const GET_CRYPTOS_CHANGE_REQUEST = "GET_CRYPTOS_CHANGE_REQUEST";
export const GET_CRYPTOS_CHANGE_SUCCESS = "GET_CRYPTOS_CHANGE_SUCCESS";
export const GET_CRYPTOS_CHANGE_ERROR = "GET_CRYPTOS_CHANGE_ERROR";

export const META1_CONNECT_SUCCESS = "META1_CONNECT_SUCCESS";

export const SET_USER_CURRENCY = "SET_USER_CURRENCY";

export const SAVE_USER_CURRENCY_REQUEST  = "SAVE_USER_CURRENCY_REQUEST";
export const SAVE_USER_CURRENCY_SUCCESS  = "SAVE_USER_CURRENCY_SUCCESS";
export const SAVE_USER_CURRENCY_ERROR  = "SAVE_USER_CURRENCY_ERROR";
export const SAVE_USER_CURRENCY_RESET = "SAVE_USER_CURRENCY_RESET";

export const SAVE_BALANCE_REQUEST = "SAVE_BALANCE_REQUEST";
export const SAVE_BALANCE_SUCCESS = "SAVE_BALANCE_SUCCESS";
export const SAVE_BALANCE_ERROR = "SAVE_BALANCE_ERROR"; 

export const RESET_META_STORE = "RESET_META_STORE";
