// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.needToBeYellow {
    background: var(--gold);
    color: #fff;
    margin-left: 3.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/LeftPanelAdapt/LeftPanelAdapt.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,mBAAmB;AACvB","sourcesContent":[".needToBeYellow {\n    background: var(--gold);\n    color: #fff;\n    margin-left: 3.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
