// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100%;
  background: var(--backgroundColor);
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  height: 300px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  display: flex;
  width: 100%;
  align-items: center;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--textBrown) !important;
}

.wallet-input-field {
  width: 100%;
}

.wallet-option {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  outline: none;
  padding-left: 10px;
}

.wallet-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-option-picture img {
  width: 40px;
  margin-right: 15px;
  /* z-index: 100; */
}`, "",{"version":3,"sources":["webpack://./src/components/Settings/settings.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB","sourcesContent":[".css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {\n  width: 100%;\n  background: var(--backgroundColor);\n}\n\n.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {\n  height: 300px;\n}\n\n.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {\n  display: flex;\n  width: 100%;\n  align-items: center;\n}\n\n.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {\n  color: var(--textBrown) !important;\n}\n\n.wallet-input-field {\n  width: 100%;\n}\n\n.wallet-option {\n  display: flex;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  margin-top: 0px;\n  margin-bottom: 0px;\n  cursor: pointer;\n  outline: none;\n  padding-left: 10px;\n}\n\n.wallet-input {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.wallet-option-picture img {\n  width: 40px;\n  margin-right: 15px;\n  /* z-index: 100; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
