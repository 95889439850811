export const scamAccountsPolo = [
    "polonie-wallet",
    "1.2.27842",
    "polonie-xwallet",
    "1.2.27850",
    "poloniewallet",
    "1.2.20873",
    "poloniex-deposit",
    "1.2.25426",
    "poloniex-wallet",
    "1.2.20821",
    "poloniexwall-et",
    "1.2.28079",
    "poloniexwallett",
    "1.2.28188",
    "poloniexwall-t",
    "1.2.28072",
    "poloniexwalle",
    "1.2.20580",
    "poloniex",
    "1.2.2340",
    "poloneix",
    "1.2.24900"
];

export const scamAccountsBittrex = [
    "bittrex-deopsit",
    "1.2.34361",
    "bittrex-deposi",
    "1.2.23638",
    "bittrex-depositt",
    "1.2.23640",
    "bittrex-dposit",
    "1.2.32594",
    "bittrex",
    "1.2.2343",
    "bittrex-deposits",
    "1.2.161093"
];

export const scamAccountsOther = [
    // "blockchainprojectsbv-test-2", for testing
    // "1.2.894879",
    "aacoin",
    "1.2.1005892",
    "abucoins",
    "1.2.463335",
    "acinq",
    "1.2.878058",
    "aex-bitcny-deposit",
    "1.2.455052",
    "aex-bts-deposit-walle",
    "1.2.454205",
    "aex-cnc-cza",
    "1.2.878029",
    "aex88",
    "1.2.831039",
    "aexdepositbm",
    "1.2.1031653",
    "aida",
    "1.2.539184",
    "aidosmarket",
    "1.2.787065",
    "allah-iraq",
    "1.2.979541",
    "aliant",
    "1.2.878061",
    "alta",
    "1.2.878079",
    "altcointrader",
    "1.2.787049",
    "antpool",
    "1.2.524132",
    "aporoo",
    "1.2.1018792",
    "apus",
    "1.2.999997",
    "arcbit",
    "1.2.465096",
    "archive",
    "1.2.786888",
    "armory",
    "1.2.465097",
    "ate-io-bts",
    "1.2.730842",
    "atoken",
    "1.2.599845",
    "azex",
    "1.2.986608",
    "b2bx",
    "1.2.897119",
    "bancor.network",
    "1.2.781941",
    "barterdex",
    "1.2.787093",
    "baseline",
    "1.2.599803",
    "bbex",
    "1.2.484215",
    "bbts001",
    "1.2.815730",
    "bc1q",
    "1.2.791043",
    "bcoin",
    "1.2.1029906",
    "bcos",
    "1.2.446259",
    "beechat",
    "1.2.599849",
    "bepal",
    "1.2.605394",
    "bft-dpos",
    "1.2.1026134",
    "bgogo",
    "1.2.1007568",
    "bhex",
    "1.2.1030020",
    "bibox.com",
    "1.2.621440",
    "bigbag",
    "1.2.789783",
    "bigether",
    "1.2.694013",
    "bigether.vip",
    "1.2.694015",
    "bilaxy",
    "1.2.1023782",
    "binance-btc-1",
    "1.2.467525",
    "binance-bts-i",
    "1.2.467527",
    "binance-bts-l",
    "1.2.467526",
    "binancecleos",
    "1.2.1017616",
    "bishengtopex",
    "1.2.1029188",
    "bisq",
    "1.2.787078",
    "bisq.network",
    "1.2.787079",
    "bitarg",
    "1.2.878042",
    "bitasiaex",
    "1.2.878018",
    "bitbill",
    "1.2.524063",
    "bitbill.app",
    "1.2.1000037",
    "bitbns",
    "1.2.782094",
    "bitcoinindonesi",
    "1.2.609628",
    "bitcointrade",
    "1.2.781957",
    "bitfinexdep1",
    "1.2.1019320",
    "bitflip",
    "1.2.787068",
    "bitflyer.com",
    "1.2.901469",
    "bithd",
    "1.2.745701",
    "bitholic",
    "1.2.787035",
    "bitinka",
    "1.2.781928",
    "bitkk",
    "1.2.878012",
    "bitkkchubei00",
    "1.2.815733",
    "bitkkchubei001",
    "1.2.815732",
    "bitkkchubei02",
    "1.2.815734",
    "bitkonan",
    "1.2.787113",
    "bitkop",
    "1.2.962262",
    "bitmaszyna",
    "1.2.787101",
    "bitoex",
    "1.2.599690",
    "bitonic",
    "1.2.781953",
    "bitopro",
    "1.2.885538",
    "bitpaction",
    "1.2.878571",
    "bitpal",
    "1.2.599671",
    "bitpesa",
    "1.2.742633",
    "bitpie",
    "1.2.465092",
    "bitrefill",
    "1.2.878076",
    "bits.farm",
    "1.2.749087",
    "bitsblockchain",
    "1.2.781926",
    "bitsfarm",
    "1.2.749090",
    "bitshares-app",
    "1.2.1000006",
    "bitshares.app",
    "1.2.1000008",
    "bitspark-hold",
    "1.2.1032019",
    "bitun",
    "1.2.782084",
    "bitupro",
    "1.2.878037",
    "bitvip.com",
    "1.2.599832",
    "bjex",
    "1.2.540676",
    "bkex",
    "1.2.988882",
    "blind",
    "1.2.745696",
    "blind-transfer",
    "1.2.745698",
    "blocktrade",
    "1.2.161090",
    "bloex",
    "1.2.1030028",
    "blox",
    "1.2.940022",
    "bonus-event-in-end-of-years-2018",
    "1.2.1208797",
    "bsend001",
    "1.2.815731",
    "btc-alpha.com",
    "1.2.781944",
    "btc.eos",
    "1.2.659986",
    "btc38.com",
    "1.2.92089",
    "btcalph",
    "1.2.878023",
    "btcmarkets.net",
    "1.2.781936",
    "btcxindia",
    "1.2.782095",
    "btex",
    "1.2.1026071",
    "bts.app",
    "1.2.1000010",
    "bts.eos",
    "1.2.659985",
    "btsalpha",
    "1.2.878025",
    "metaId",
    "1.2.599680",
    "btsmoney",
    "1.2.32810",
    "burstnation",
    "1.2.787122",
    "buyucoin",
    "1.2.812978",
    "buyucoin-walle",
    "1.2.812979",
    "c-patex",
    "1.2.787114",
    "cashaddr",
    "1.2.791045",
    "cashierest",
    "1.2.878112",
    "cetus",
    "1.2.1000009",
    "cex.com",
    "1.2.599797",
    "chaince",
    "1.2.939977",
    "chaince.com",
    "1.2.939982",
    "chainceoneos",
    "1.2.1020153",
    "changelly",
    "1.2.599892",
    "changenow",
    "1.2.620292",
    "chaoex.com",
    "1.2.599812",
    "citcon",
    "1.2.878074",
    "cobo",
    "1.2.742630",
    "cobowalletcn",
    "1.2.1026084",
    "cobowalletio",
    "1.2.1026083",
    "cohescure",
    "1.2.705164",
    "coin.space",
    "1.2.599877",
    "coin2co",
    "1.2.1026070",
    "coin900",
    "1.2.557720",
    "coin900.com",
    "1.2.620298",
    "coinbase",
    "1.2.709",
    "coinbase.com",
    "1.2.92130",
    "coinbe",
    "1.2.787120",
    "coinbell",
    "1.2.782088",
    "coinbene",
    "1.2.465091",
    "coinbig",
    "1.2.707653",
    "coinbig.com",
    "1.2.707655",
    "coincode",
    "1.2.599683",
    "coincoming",
    "1.2.620294",
    "coineal",
    "1.2.999276",
    "coinegg.main",
    "1.2.447093",
    "coinex",
    "1.2.467667",
    "coinex.com",
    "1.2.553120",
    "coinexchange",
    "1.2.463328",
    "coinexmarket",
    "1.2.897140",
    "coinfloorex",
    "1.2.812983",
    "coingeto",
    "1.2.878077",
    "coinhouse",
    "1.2.787106",
    "coinhub",
    "1.2.897122",
    "coinlink",
    "1.2.787076",
    "coinmate",
    "1.2.463339",
    "coinoah",
    "1.2.940511",
    "coinoah.com",
    "1.2.940514",
    "coinpark",
    "1.2.999275",
    "coinrail",
    "1.2.781937",
    "coinsave",
    "1.2.999292",
    "coinstocks",
    "1.2.745645",
    "cointiger-deposit-bts",
    "1.2.1029192",
    "cointiger-deposit-bts0",
    "1.2.1029193",
    "cointiger-deposit-bts1",
    "1.2.1029194",
    "cointobe",
    "1.2.599669",
    "cointome",
    "1.2.693998",
    "cointopay",
    "1.2.742639",
    "coinut",
    "1.2.463336",
    "coinut.com",
    "1.2.781955",
    "coinw",
    "1.2.462799",
    "coinw.com",
    "1.2.599798",
    "coinyee",
    "1.2.878013",
    "coldlar",
    "1.2.465101",
    "committee",
    "1.2.599999",
    "committee-101",
    "1.2.999719",
    "committee-102",
    "1.2.999727",
    "committee-103",
    "1.2.999720",
    "committee-104",
    "1.2.999724",
    "committee-105",
    "1.2.999722",
    "committee-106",
    "1.2.999721",
    "committee-107",
    "1.2.999723",
    "committee-108",
    "1.2.999725",
    "committee-109",
    "1.2.999728",
    "committee-110",
    "1.2.999726",
    "committee-accou",
    "1.2.999694",
    "committee-accoun",
    "1.2.807841",
    "committee-accounr",
    "1.2.500266",
    "committee-cnytrade",
    "1.2.999697",
    "committee-usdoperato",
    "1.2.999696",
    "committe-security",
    "1.2.1273199",
    "contractland",
    "1.2.878062",
    "counterwallet",
    "1.2.787112",
    "crux",
    "1.2.999998",
    "cryptobridg",
    "1.2.614370",
    "cryptobridge-payout",
    "1.2.745721",
    "cryptoderivatives",
    "1.2.787129",
    "cryptoderivatives.market",
    "1.2.787130",
    "cryptomkt",
    "1.2.787095",
    "cryptomkt.com",
    "1.2.787094",
    "crypton",
    "1.2.1026067",
    "cryptonize",
    "1.2.999284",
    "crypviser",
    "1.2.1026359",
    "ctpmall",
    "1.2.599726",
    "cx-bitcny-deposit-wallet",
    "1.2.827934",
    "cx-bitcny-withdraw-wallet",
    "1.2.827938",
    "cx-bts-deposit-wallet",
    "1.2.827946",
    "cx-bts-withdraw-wallet",
    "1.2.827943",
    "daex",
    "1.2.792704",
    "dax-deposit",
    "1.2.970985",
    "dax-exchange",
    "1.2.970987",
    "dax-mn",
    "1.2.970989",
    "dax-withdraw",
    "1.2.970993",
    "ddex",
    "1.2.599757",
    "decred",
    "1.2.742642",
    "dewone",
    "1.2.749261",
    "dex.top",
    "1.2.962058",
    "dexko",
    "1.2.694029",
    "dgtmarket",
    "1.2.787107",
    "diceex",
    "1.2.999511",
    "diceex-bit",
    "1.2.999518",
    "diceex-bitcn",
    "1.2.999513",
    "diceex-bitusd",
    "1.2.999517",
    "diceex-btc",
    "1.2.999514",
    "diceex-cny",
    "1.2.999516",
    "dig-exchange",
    "1.2.795378",
    "digifinex",
    "1.2.878066",
    "digitaex",
    "1.2.693969",
    "door.one",
    "1.2.620671",
    "dragonex",
    "1.2.599785",
    "eclair",
    "1.2.878057",
    "ecoex-bts-1",
    "1.2.990386",
    "eidoo",
    "1.2.782093",
    "eip-20",
    "1.2.658953",
    "eip20",
    "1.2.658949",
    "electrum",
    "1.2.465095",
    "eligma",
    "1.2.878056",
    "entapay",
    "1.2.787075",
    "enumivo",
    "1.2.898598",
    "eos.btc",
    "1.2.659984",
    "eos.bts",
    "1.2.659983",
    "eosdac",
    "1.2.878054",
    "eosfinix",
    "1.2.742627",
    "erc-20",
    "1.2.611002",
    "erc-721",
    "1.2.611005",
    "erc721",
    "1.2.611000",
    "etherflyer",
    "1.2.749253",
    "etherflyer.com",
    "1.2.749255",
    "ethfinex",
    "1.2.781930",
    "ewex",
    "1.2.999306",
    "excambriorex",
    "1.2.787124",
    "exnow",
    "1.2.1006068",
    "expie",
    "1.2.658199",
    "expie.com",
    "1.2.658200",
    "exvo",
    "1.2.878032",
    "exx.com",
    "1.2.599826",
    "exxbtc001",
    "1.2.815746",
    "exxbts00",
    "1.2.815718",
    "exxbts01",
    "1.2.815719",
    "exxsend00",
    "1.2.815720",
    "exxsend01",
    "1.2.815723",
    "ezbtc",
    "1.2.787057",
    "fargobase",
    "1.2.463337",
    "fhex",
    "1.2.1030027",
    "firefoxotc",
    "1.2.812985",
    "forkdelta",
    "1.2.766759",
    "freiexchange",
    "1.2.787118",
    "gate-io-b",
    "1.2.743303",
    "gate-io-bt",
    "1.2.730841",
    "gate-io-btc",
    "1.2.730836",
    "gateio",
    "1.2.797076",
    "gateiowallet",
    "1.2.1020027",
    "gcox",
    "1.2.789782",
    "gdexioforeos",
    "1.2.1031969",
    "geos",
    "1.2.940146",
    "geta-io-bts",
    "1.2.730843",
    "geth",
    "1.2.599793",
    "ggex",
    "1.2.999285",
    "glidera",
    "1.2.878068",
    "greenaddress",
    "1.2.599879",
    "greenbits",
    "1.2.599875",
    "grus",
    "1.2.999999",
    "guldentrader",
    "1.2.787126",
    "hackers",
    "1.2.21846",
    "hadax",
    "1.2.705160",
    "hadax.com",
    "1.2.705162",
    "hbus",
    "1.2.1026050",
    "hcoin",
    "1.2.1018787",
    "heatwallet",
    "1.2.787111",
    "heatwallet.com",
    "1.2.787109",
    "heztanrqgene",
    "1.2.1020148",
    "hitbtc-exchang",
    "1.2.984724",
    "hitbtc.exchange",
    "1.2.984983",
    "hotbit",
    "1.2.643056",
    "huobi-bts-deposi",
    "1.2.852510",
    "huobi-deposi",
    "1.2.852506",
    "huobideposit",
    "1.2.1019310",
    "huobipro",
    "1.2.764427",
    "iceex-bitcny",
    "1.2.999519",
    "ico-china",
    "1.2.705561",
    "icoape",
    "1.2.706057",
    "icoape.com",
    "1.2.706060",
    "idax",
    "1.2.787045",
    "idax-deposi",
    "1.2.970984",
    "idax-exchang",
    "1.2.970986",
    "idax-nm",
    "1.2.970988",
    "idax-withdra",
    "1.2.970991",
    "idax.deposit",
    "1.2.970994",
    "idax.exchange",
    "1.2.970995",
    "idax.withdraw",
    "1.2.970997",
    "idex",
    "1.2.749252",
    "idex.market",
    "1.2.749251",
    "idle",
    "1.2.900001",
    "ifish",
    "1.2.800510",
    "independentreserve",
    "1.2.781950",
    "indodax",
    "1.2.878015",
    "indus",
    "1.2.1000007",
    "infinitycoin",
    "1.2.787092",
    "infinitycoin.exchange",
    "1.2.787091",
    "iquant",
    "1.2.787044",
    "issuer",
    "1.2.424070",
    "itbtc-exchange",
    "1.2.984791",
    "jaxx",
    "1.2.524055",
    "jrex",
    "1.2.1030022",
    "kairex",
    "1.2.878049",
    "kcash",
    "1.2.562936",
    "keepkey",
    "1.2.599863",
    "kkcoin",
    "1.2.519919",
    "kkcoin.wallet",
    "1.2.519960",
    "kkcoinwallet",
    "1.2.519959",
    "koineks.com",
    "1.2.781952",
    "koinim.com",
    "1.2.781958",
    "krakenkraken",
    "1.2.1020138",
    "kucoin",
    "1.2.463330",
    "kucoindoteos",
    "1.2.1084351",
    "lakebtc",
    "1.2.463326",
    "lakebtc.com",
    "1.2.781938",
    "latoken",
    "1.2.897147",
    "lbank.info",
    "1.2.621443",
    "leoxchange",
    "1.2.787121",
    "licai.deposit",
    "1.2.675961",
    "liteforex",
    "1.2.782098",
    "litepay",
    "1.2.742614",
    "liteshares",
    "1.2.899346",
    "livecoi-net",
    "1.2.183860",
    "livecoin.net",
    "1.2.322811",
    "livecoinnet",
    "1.2.322812",
    "llex",
    "1.2.999290",
    "locktrades",
    "1.2.161091",
    "loois",
    "1.2.999301",
    "loom",
    "1.2.897934",
    "ls123",
    "1.2.394942",
    "luckygame",
    "1.2.971018",
    "luckygames",
    "1.2.971013",
    "luckygames-i",
    "1.2.971014",
    "lupus",
    "1.2.1000005",
    "lykke.com",
    "1.2.787036",
    "lyra",
    "1.2.1000000",
    "mai1100",
    "1.2.158598",
    "mark.space",
    "1.2.897148",
    "meet-up",
    "1.2.999282",
    "meet.one",
    "1.2.782091",
    "meetup",
    "1.2.999280",
    "memo.cash",
    "1.2.878020",
    "mensa",
    "1.2.1000004",
    "mercadobitcoin",
    "1.2.781951",
    "metamask",
    "1.2.963272",
    "mexc",
    "1.2.924294",
    "mmex",
    "1.2.999287",
    "more.top",
    "1.2.782089",
    "morphtoken",
    "1.2.878065",
    "msigna",
    "1.2.465098",
    "mycelium",
    "1.2.599873",
    "mycrypto",
    "1.2.742624",
    "mydicewallet",
    "1.2.787099",
    "mydicewallet.com",
    "1.2.787096",
    "myetherwallet",
    "1.2.599843",
    "mywallet-1023",
    "1.2.999689",
    "n0stradumpus",
    "1.2.435299",
    "nanex",
    "1.2.897150",
    "negociecoins",
    "1.2.897142",
    "neraex",
    "1.2.463333",
    "neraex.pro",
    "1.2.781933",
    "nevbit",
    "1.2.463340",
    "new.one",
    "1.2.782090",
    "nicehash",
    "1.2.812986",
    "nix-e",
    "1.2.787123",
    "nocks",
    "1.2.787070",
    "oasisdex",
    "1.2.787061",
    "obit-wallet",
    "1.2.800539",
    "octaex",
    "1.2.897139",
    "ocx-bitcny-deposit-walle",
    "1.2.827932",
    "ocx-bitcny-withdraw-walle",
    "1.2.827937",
    "ocx-bts-deposit-walle",
    "1.2.827945",
    "ocx-bts-withdraw-walle",
    "1.2.827942",
    "oex.com",
    "1.2.694035",
    "ointiger-deposit-bts01",
    "1.2.1029195",
    "ojbk",
    "1.2.878030",
    "okbtothemoon",
    "1.2.1029923",
    "okcoinkr",
    "1.2.854387",
    "omicrex",
    "1.2.897131",
    "ommittee-account",
    "1.2.999695",
    "omniexplorer",
    "1.2.787128",
    "omniexplorer.info",
    "1.2.787127",
    "onchainfx",
    "1.2.742618",
    "onchainfx.com",
    "1.2.742621",
    "ooex",
    "1.2.1026069",
    "ooobtc",
    "1.2.897124",
    "openledger-support",
    "1.2.153124",
    "otcbtc",
    "1.2.599673",
    "otcbtcdotcom",
    "1.2.1020116",
    "otcbtcdotnet",
    "1.2.1028053",
    "otcwallet",
    "1.2.1029165",
    "otcx",
    "1.2.492286",
    "ourdax",
    "1.2.599817",
    "ovis",
    "1.2.897135",
    "p2pbucks",
    "1.2.766633",
    "palmpay",
    "1.2.745458",
    "paradex",
    "1.2.962048",
    "pavo",
    "1.2.1000001",
    "pay.bts.com",
    "1.2.92203",
    "pay.coinbase.com",
    "1.2.92185",
    "paymium",
    "1.2.787072",
    "pieotc",
    "1.2.878047",
    "pinocaitoken",
    "1.2.1026118",
    "pocketeos",
    "1.2.1026453",
    "poim",
    "1.2.475488",
    "pomelo",
    "1.2.965566",
    "protokenbank",
    "1.2.1026086",
    "pxn-eos",
    "1.2.1028325",
    "pxn-incom",
    "1.2.1026076",
    "pxn.eos",
    "1.2.1028326",
    "pxn.one",
    "1.2.999512",
    "pxneos",
    "1.2.1028324",
    "pxneos-income",
    "1.2.1028328",
    "pxneosincome",
    "1.2.1028322",
    "pxnincome",
    "1.2.1026079",
    "pyxis",
    "1.2.1000003",
    "qbao",
    "1.2.650495",
    "qcash",
    "1.2.473774",
    "qlink",
    "1.2.878073",
    "qtum-electrum",
    "1.2.761861",
    "quadrigacx",
    "1.2.463332",
    "rabobit",
    "1.2.782096",
    "radarrelay",
    "1.2.787081",
    "radarrelay.com",
    "1.2.787080",
    "ranswiser-wallet",
    "1.2.161364",
    "referrer",
    "1.2.745704",
    "renrenbit",
    "1.2.999291",
    "richcore",
    "1.2.688600",
    "richcore.com",
    "1.2.688621",
    "rippex",
    "1.2.787117",
    "ripple.com",
    "1.2.92097",
    "ripplechina.net",
    "1.2.787054",
    "ripplefox",
    "1.2.787038",
    "rootoken",
    "1.2.742632",
    "rrex",
    "1.2.999289",
    "rudexgateway",
    "1.2.1031975",
    "safewallet",
    "1.2.693983",
    "secp256k1",
    "1.2.539247",
    "sendy",
    "1.2.878072",
    "simex.global",
    "1.2.781934",
    "sistemkoin",
    "1.2.897127",
    "spectrocoin",
    "1.2.898120",
    "stealth",
    "1.2.745699",
    "stellarterm",
    "1.2.787039",
    "stellarterm.com",
    "1.2.787040",
    "stex",
    "1.2.749264",
    "stex.exchange",
    "1.2.749263",
    "stronghold",
    "1.2.781945",
    "superleague1",
    "1.2.1026458",
    "support-team",
    "1.2.1265329",
    "swft.pro",
    "1.2.940110",
    "swftc",
    "1.2.533080",
    "swftcoin",
    "1.2.533082",
    "tcalpha",
    "1.2.878024",
    "tcash",
    "1.2.749256",
    "tdax",
    "1.2.693995",
    "tdax.com",
    "1.2.693996",
    "tdex",
    "1.2.1002884",
    "therocktrading",
    "1.2.787052",
    "therocktrading.com",
    "1.2.787051",
    "thinkbit",
    "1.2.1007570",
    "tidebit",
    "1.2.787053",
    "token.store",
    "1.2.787056",
    "token360",
    "1.2.999298",
    "token365",
    "1.2.999295",
    "tokenlon",
    "1.2.878033",
    "tokenxx",
    "1.2.754276",
    "top.one",
    "1.2.599746",
    "topbtc",
    "1.2.463334",
    "topbtc.com",
    "1.2.781935",
    "toshi",
    "1.2.878046",
    "tradebytrade",
    "1.2.781947",
    "tradebytrade.com",
    "1.2.781946",
    "tradeogre",
    "1.2.787088",
    "tradesatoshi.com",
    "1.2.787041",
    "transwiser-walle",
    "1.2.161362",
    "transwiser-wallets",
    "1.2.161363",
    "truecoin",
    "1.2.800533",
    "trueusd",
    "1.2.791378",
    "trusty.fund",
    "1.2.878014",
    "tusd",
    "1.2.800535",
    "tuxexchange",
    "1.2.787103",
    "tuxexchange.com",
    "1.2.787102",
    "u-coin",
    "1.2.494585",
    "uckygames-io",
    "1.2.971015",
    "unblock",
    "1.2.878069",
    "uncoinex",
    "1.2.475307",
    "unicode",
    "1.2.800540",
    "uniex",
    "1.2.1007569",
    "uobi-bts-deposit",
    "1.2.852511",
    "uobi-deposit",
    "1.2.852508",
    "upbitwallets",
    "1.2.1020123",
    "usadae",
    "1.2.887944",
    "usadae.com",
    "1.2.887947",
    "usdc",
    "1.2.929449",
    "utxo",
    "1.2.878026",
    "uuex",
    "1.2.1026068",
    "uyucoin-wallet",
    "1.2.812980",
    "vebitcoin",
    "1.2.781940",
    "vela",
    "1.2.1000002",
    "virtacoinworld",
    "1.2.787131",
    "vuniyuoxoeub",
    "1.2.1026794",
    "vvbtc",
    "1.2.959755",
    "walletdunamu",
    "1.2.1020156",
    "wazirx",
    "1.2.742650",
    "wetez",
    "1.2.1026072",
    "winex.pro",
    "1.2.599675",
    "wirex",
    "1.2.599807",
    "www.binance.com",
    "1.2.553014",
    "www.coinex.com",
    "1.2.553121",
    "www.hadax.com",
    "1.2.705161",
    "www.usadae.com",
    "1.2.887949",
    "www.yyex.com",
    "1.2.731038",
    "www.zhaobi.com",
    "1.2.675956",
    "xbrick",
    "1.2.458197",
    "xbrick.btc",
    "1.2.459690",
    "xbrick.cny",
    "1.2.459688",
    "xbrick.usd",
    "1.2.459689",
    "xbtce",
    "1.2.463329",
    "xdaex",
    "1.2.1026062",
    "xdag",
    "1.2.999278",
    "xn-income",
    "1.2.1026077",
    "xxbts001",
    "1.2.815729",
    "xxsend001",
    "1.2.815727",
    "yex.com",
    "1.2.734592",
    "yhex",
    "1.2.1030023",
    "yobit-wall",
    "1.2.800538",
    "yobit-walle",
    "1.2.800536",
    "yobtc",
    "1.2.599688",
    "yun.bts",
    "1.2.161529",
    "yunbi.com",
    "1.2.92101",
    "yuxiao",
    "1.2.1031166",
    "yyex",
    "1.2.731035",
    "yyex.com",
    "1.2.731037",
    "z-201",
    "1.2.1025474",
    "zbbtc",
    "1.2.442315",
    "zbbtc001",
    "1.2.815745",
    "zbbts00",
    "1.2.815716",
    "zbbts01",
    "1.2.815717",
    "zbeoscharge1",
    "1.2.1019012",
    "zbsen",
    "1.2.745722",
    "zbsend00",
    "1.2.815712",
    "zbsend01",
    "1.2.815714",
    "zecoex",
    "1.2.990383",
    "zecoex-btc",
    "1.2.990385",
    "zecoex-bts",
    "1.2.990384",
    "zeniex",
    "1.2.681261",
    "zhaobi",
    "1.2.599681",
    "zhaobi.com",
    "1.2.675955",
    "zhex",
    "1.2.1030026",
    "zilla",
    "1.2.878051",
    "openledger-gex",
    "1.2.1540900",
    "enter",
    "1.2.12300",
    "hi",
    "1.2.707",
    "bts",
    "1.2.383",
    "eos-bounties",
    "1.2.1055529",
    "jobs001",
    "1.2.1147979",
    "noble-group",
    "1.2.997905",
    "my-change",
    "1.2.637403",
    "openledger-upgrade",
    "1.2.1544053",
    "cryptobridge-upgrade",
    "1.2.1570095",
    "aex-bts-deposite-wallet",
    "1.2.1586667",
    "gdex-security",
    "1.2.1568938",
    "escrow-pannf",
    "1.2.1586672",
    "openledger-security",
    "1.2.1547751",
    "openlegder-dex",
    "1.2.1566849",
    "committee-collateral",
    "1.2.1607515",
    "toolate1488",
    "1.2.486728",
    "beos-upgrade",
    "1.2.1600697",
    "cryptobridge-guarantee",
    "1.2.1602050",
    "construction-rates",
    "1.2.184377",
    "committee-update",
    "1.2.1600131",
    "committee-securuti",
    "1.2.1544160",
    "committee-seccurity",
    "1.2.1546307",
    "committee-proposalss",
    "1.2.1559233",
    "committee-account-update",
    "1.2.1589838",
    "committee-security-update",
    "1.2.1592605",
    "committee-accounr",
    "1.2.500266"
];
