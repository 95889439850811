// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*// Notification.css*/
.hud-user-guidance-text {
	position: absolute;
	bottom: 2%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 0.3s ease-in-out;
	width: 100%;
	/*opacity: 0;*/
}

.ant-alert {
	background: transparent !important;
	border: none;
}

.ant-alert-message {
	color: white;
}
`, "",{"version":3,"sources":["webpack://./src/modules/biometric-auth/css/user-gudance-text.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;CACC,kBAAkB;CAClB,UAAU;CACV,SAAS;CACT,gCAAgC;CAChC,oCAAoC;CACpC,WAAW;CACX,cAAc;AACf;;AAEA;CACC,kCAAkC;CAClC,YAAY;AACb;;AAEA;CACC,YAAY;AACb","sourcesContent":["/*// Notification.css*/\n.hud-user-guidance-text {\n\tposition: absolute;\n\tbottom: 2%;\n\tleft: 50%;\n\ttransform: translate(-50%, -50%);\n\ttransition: opacity 0.3s ease-in-out;\n\twidth: 100%;\n\t/*opacity: 0;*/\n}\n\n.ant-alert {\n\tbackground: transparent !important;\n\tborder: none;\n}\n\n.ant-alert-message {\n\tcolor: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
