// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUpForm_signupBlock__mXRZg {
  background: transparent;
  border: 1px solid var(--borderColor);
  color: var(--textBrown);
  width: 100%;
  height: 3.7rem;
  padding: 1.1rem 2rem;
  box-shadow: 0 9px 10px 0 rgba(0, 0, 0, 0.11);
  font-size: 1.3rem;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/SignUpForm/SignUpForm.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,oCAAA;EACA,uBAAA;EACA,WAAA;EACA,cAAA;EACA,oBAAA;EACA,4CAAA;EACA,iBAAA;EACA,iBAAA;AACF","sourcesContent":[".signupBlock {\n  background: transparent;\n  border: 1px solid var(--borderColor);\n  color: var(--textBrown);\n  width: 100%;\n  height: 3.7rem;\n  padding: 1.1rem 2rem;\n  box-shadow: 0 9px 10px 0 rgba(0,0,0,0.11);\n  font-size: 1.3rem;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signupBlock": `SignUpForm_signupBlock__mXRZg`
};
export default ___CSS_LOADER_EXPORT___;
