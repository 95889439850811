import google from '../../images/iconProviders/google.svg';
import facebook from '../../images/iconProviders/facebook.svg';
import apple from '../../images/iconProviders/apple.svg';
import discord from '../../images/iconProviders/discord.svg';
import email from '../../images/iconProviders/email.svg';
import github from '../../images/iconProviders/github.svg';
import jwt from '../../images/iconProviders/jwt.svg';
import kakao from '../../images/iconProviders/kakao.svg';
import line from '../../images/iconProviders/line.svg';
import linkedin from '../../images/iconProviders/linkedin.svg';
import reddit from '../../images/iconProviders/reddit.svg';
import twitch from '../../images/iconProviders/twitch.svg';
import twitter from '../../images/iconProviders/twitter.svg';
import weibo from '../../images/iconProviders/weibo.svg';
import weixin from '../../images/iconProviders/weixin.svg';

export const providers = [
    {
        id: 1,
        name: 'google',
        image: google
    },
    {
        id: 2,
        name: 'facebook',
        image: facebook
    },
    {
        id: 4,
        name: 'discord',
        image: discord
    },
    {
        id: 6,
        name: 'apple',
        image: apple
    },
    {
        id: 8,
        name: 'github',
        image: github
    },
];
