// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orderlist_orderlist__JTgx\\+ {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.orderlist_orderlist__JTgx\\+ .orderlist_totalOrders__\\+-14D {
  width: 100%;
  border-radius: 5px;
  background: white;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-shadow: 0px 5px 20px 0px rgba(51, 0, 0, 0.2);
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
}
.orderlist_orderlist__JTgx\\+ .orderlist_submitButton__Usu2d {
  background: var(--textGold);
  color: var(--textBrown);
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
  width: 300px;
  margin-top: 20px;
  height: 60px;
}
.orderlist_orderlist__JTgx\\+ .orderlist_noFoundImg__taukH {
  width: 100px;
}
.orderlist_orderlist__JTgx\\+ .orderlist_refreshImg__rq\\+k\\+ {
  width: 20px;
  margin-right: 20px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/WireCheck/orderlist.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,gDAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,8BAAA;AACJ;AAEE;EACE,2BAAA;EACA,uBAAA;EACA,0BAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;AAAJ;AAGE;EACE,YAAA;AADJ;AAIE;EACE,WAAA;EACA,kBAAA;EACA,eAAA;AAFJ","sourcesContent":[".orderlist {\n  padding-left: 30px;\n  padding-right: 30px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  .totalOrders {\n    width: 100%;\n    border-radius: 5px;\n    background: white;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    padding-left: 20px;\n    box-shadow: 0px 5px 20px 0px rgba(51, 0, 0, 0.20);\n    margin-bottom: 10px;\n    font-size: 14px;\n    font-weight: 600;\n    justify-content: space-between;\n  }\n\n  .submitButton {\n    background: var(--textGold);\n    color: var(--textBrown);\n    text-transform: capitalize;\n    font-size: 20px;\n    font-weight: 600;\n    width: 300px;\n    margin-top: 20px;\n    height: 60px;\n  }\n\n  .noFoundImg {\n    width: 100px;\n  }\n\n  .refreshImg {\n    width: 20px;\n    margin-right: 20px;\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderlist": `orderlist_orderlist__JTgx+`,
	"totalOrders": `orderlist_totalOrders__+-14D`,
	"submitButton": `orderlist_submitButton__Usu2d`,
	"noFoundImg": `orderlist_noFoundImg__taukH`,
	"refreshImg": `orderlist_refreshImg__rq+k+`
};
export default ___CSS_LOADER_EXPORT___;
