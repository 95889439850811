// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bitrate-monitor {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0);
    padding: 2px;
    border-radius: 1px;
    z-index: 1000;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/modules/biometric-auth/css/bitrate-monitor.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,kCAAkC;IAClC,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".bitrate-monitor {\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    background-color: rgba(0, 0, 0, 0);\n    padding: 2px;\n    border-radius: 1px;\n    z-index: 1000;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
