// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_container__iICw7 {
  height: 3rem;
  background: var(--footColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--textGrey);
  font-weight: 600;
}
.Footer_container__iICw7 ul {
  display: flex;
  flex-direction: row;
  width: 30rem;
}
.Footer_container__iICw7 ul li {
  text-align: center;
  font-size: 0.7rem;
  padding: 1rem 0.5rem;
  cursor: pointer;
}
@media (max-width: 600px) {
  .Footer_container__iICw7 {
    height: 100%;
    flex-direction: column;
  }
}

.Footer_mainDiv__5u0wq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Footer_copyrightPc__PPD7j {
  padding: 0.9rem 0 0.9rem 3rem;
  width: 13rem;
}

.Footer_copyrightMobile__Pr2mY {
  padding: 1rem;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,sBAAA;EACA,gBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;AAEJ;AADI;EACE,kBAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;AAGN;AAAE;EAnBF;IAoBI,YAAA;IACA,sBAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAGF;;AAAA;EACE,6BAAA;EACA,YAAA;AAGF;;AAAA;EACE,aAAA;EACA,cAAA;AAGF","sourcesContent":[".container {\n  height: 3rem;\n  background: var(--footColor);\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  color: var(--textGrey);\n  font-weight: 600;\n  ul{\n    display: flex;\n    flex-direction: row;\n    width: 30rem;\n    li{\n      text-align: center;\n      font-size: .7rem;\n      padding: 1rem 0.5rem;\n      cursor: pointer;\n    }\n  }\n  @media (max-width: 600px) {\n    height: 100%;\n    flex-direction: column;\n  }\n}\n\n.mainDiv {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.copyrightPc {\n  padding: .9rem 0 .9rem 3rem;\n  width: 13rem;\n}\n\n.copyrightMobile {\n  padding: 1rem;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Footer_container__iICw7`,
	"mainDiv": `Footer_mainDiv__5u0wq`,
	"copyrightPc": `Footer_copyrightPc__PPD7j`,
	"copyrightMobile": `Footer_copyrightMobile__Pr2mY`
};
export default ___CSS_LOADER_EXPORT___;
