// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1oj2twp-MuiPagination-root {
    margin-bottom: 15px !important;
    margin-top: 10px !important;
}

.css-wjh20t-MuiPagination-ul {
    width: 100% !important;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background: var(--textGrey) !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {
    color: var(--calendarItemBorderColor) !important;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
    color: var(--textBrown) !important;
}

.css-1v2lvtn-MuiPaginationItem-root {
    color: var(--textBrown) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Notification/notification.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,gDAAgD;AACpD;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".css-1oj2twp-MuiPagination-root {\n    margin-bottom: 15px !important;\n    margin-top: 10px !important;\n}\n\n.css-wjh20t-MuiPagination-ul {\n    width: 100% !important;\n    justify-content: center;\n    margin-top: 10px;\n    margin-bottom: 10px;\n}\n\n.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {\n    background: var(--textGrey) !important;\n}\n\n.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-disabled {\n    color: var(--calendarItemBorderColor) !important;\n}\n\n.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {\n    color: var(--textBrown) !important;\n}\n\n.css-1v2lvtn-MuiPaginationItem-root {\n    color: var(--textBrown) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
