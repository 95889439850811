export const ceilFloat = (floatVal, precision) => {
  precision = Math.pow(10, precision);
  return Math.ceil(floatVal * precision) / precision;
}

export const floorFloat = (floatVal, precision) => {
  precision = Math.pow(10, precision);
  return Math.floor(floatVal * precision) / precision;
}

export const expFloatToFixed = (x) => {
  var e;
  if (Math.abs(x) < 1.0) {
    e = parseInt(x.toString().split('e-')[1]);
    if (e) {
        x *= Math.pow(10, e - 1);
        x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += (new Array(e + 1)).join('0');
    }
  }
  return x;
}
